@value utils: '../../styles/utils.module.css';
@value thickBorder, smallHeight from utils;

.minimap {
  position: fixed;
  left: 10px;
  bottom: calc(smallHeight + 20px);
  overflow: hidden;
  background: white;
  transition: opacity 0.33s;
  composes: thickBorder;
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  user-select: none;
}

.zoomWrapper {
  width: 100%;
  height: 100%;
  position: relative;
}

.zoom {
  position: absolute;
  border: 1px solid black;
}
