.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper span {
  margin-right: 10px;
}

.link {
  margin: 0 4px;
  padding: 0;
  transition: 0.3s transform ease-in-out;
}

.link:hover {
  transform: scale(1.2);
}

.link,
.link svg {
  width: 18px;
  height: 18px;
}
