.hamburger {
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border: none;
  background: transparent;
  border-radius: 0;
  position: relative;
}

.hamburger .line,
.hamburger::before,
.hamburger::after {
  content: '';
  display: block;
  width: 24px;
  height: 2px;
  background: black;
  transform: translate(-50%, 0) translate(2px, 0) rotate(0);
  position: absolute;
  left: 10px;
  transition: all 0.3s ease-in-out;
}

.hamburger:hover .line,
.hamburger:hover::before,
.hamburger:hover::after {
  background: greyColor;
}

.hamburger::before {
  top: 7px;
}

.hamburger .line {
  top: 12px;
  opacity: 1;
}

.hamburger::after {
  top: 17px;
}

.hamburgerIsOpen .line {
  opacity: 0;
  top: 12px;
}

.hamburgerIsOpen::before,
.hamburgerIsOpen::after {
  width: 24px;
  top: 12px;
  left: 10px;
}

.hamburgerIsOpen::before {
  transform: translate(-50%, 0) translate(2px, 0) rotate(45deg);
}

.hamburgerIsOpen::after {
  transform: translate(-50%, 0) translate(2px, 0) rotate(-45deg);
}
