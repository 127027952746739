@import 'normalize.css';

* {
  box-sizing: border-box;
}

*:focus {
  outline: none;
}

button:focus-within,
div[role='button']:focus-within,
input:focus-within,
textarea:focus-within {
  outline: none; /* 4px dashed black; */
}

@font-face {
  font-family: 'Graphik-Bold';
  src: url('./assets/fonts/Graphik-Bold.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Graphik-Bold.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Bold.woff') format('woff'),
    url('./assets/fonts/Graphik-Bold.ttf') format('truetype'),
    url('./assets/fonts/Graphik-Bold.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Graphik-Medium';
  src: url('./assets/fonts/Graphik-Medium.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/Graphik-Medium.woff2') format('woff2'),
    url('./assets/fonts/Graphik-Medium.woff') format('woff'),
    url('./assets/fonts/Graphik-Medium.ttf') format('truetype'),
    url('./assets/fonts/Graphik-Medium.svg#svgFontName') format('svg');
  font-weight: normal;
  font-style: normal;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 769px) {
  html,
  body,
  #root {
    overflow: hidden;
  }
}

body {
  margin: 0;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

body,
input,
textarea {
  font-family: 'Graphik-Medium', Arial, sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
}

rect {
  cursor: pointer;
}
