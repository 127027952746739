@value typography: '../../styles/typography.module.css';
@value n1, n1Bold, v1, underline from typography;

.h1,
.h2,
.h3 {
  font-weight: normal;
  font-style: normal;
}

.h2 {
  composes: n1;
}

.h3 {
  composes: n1;
  composes: underline;
}

.p {
  composes: n1;
}

.a {
  display: inline-block;
  color: inherit;
  composes: n1;
}

.img {
  width: 37.5%;
}

.strong {
  composes: n1Bold;
}

.legendItem {
  margin-left: 10px;
  display: inline-block;
  border-radius: 3px;
  width: 12px;
  height: 12px;
}

@media only screen and (max-width: 768px) {
  .h2 {
    margin: 60px 0 30px;
  }

  .h2:first-of-type {
    margin-top: 40px;
  }

  .h3,
  .p {
    margin: 30px 0 0;
  }
}

@media only screen and (min-width: 769px) {
  .mobileOnly {
    display: none;
  }

  .h2 {
    text-indent: 30px;
    margin: 40px 0 20px;
  }

  .h3,
  .p {
    margin: 20px 0 0;
  }
}

.p.noMargin {
  margin: 0;
}
