@value typography: '../styles/typography.module.css';
@value palette: '../styles/palette.module.css';
@value n1, n1Bold, s1Bold, responsiveTypeBig, responsiveTypeSmall from typography;

.intro {
  composes: n1;
}

.text {
  composes: s1Bold;
}

.text,
.intro {
  color: white;
}

.btn,
.intro {
  padding: 10px;
  width: 100%;
  display: block;
  border-radius: 10px;
  border: none;
  box-shadow: none;
  text-align: left;
}

.intro {
  color: black;
}

.btn:not(:disabled) {
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .grid {
    padding: 10px;
  }

  .gridItem {
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 769px) {
  .gridWrapper {
    height: 100%;
    overflow-y: auto;
    background: white;
  }

  .grid {
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
  }

  .gridItem {
    flex-basis: 16.6%;
    position: relative;
  }

  .gridItemDouble {
    flex-basis: 33.3%;
    align-items: flex-start;
  }

  .gridItemDouble .intro {
    padding: 0;
  }

  .gridItem {
    display: flex;
    border: 10px solid white;
    height: 33.33%;
    overflow: hidden;
  }

  .intro {
    composes: responsiveTypeBig;
  }

  .text {
    position: relative;
    z-index: 1;
    pointer-events: none;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    composes: responsiveTypeSmall;
  }

  .btn {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 0;
  }
}
