@value utils: '../../styles/utils.module.css';
@value palette: '../../styles/palette.module.css';
@value typography: '../../styles/typography.module.css';
@value resetBtn, thickBorder, smallHeight, minSmallHeight from utils;
@value midGrey from palette;
@value responsiveTypeBig from typography;

.logo {
  composes: resetBtn;
  text-align: left;
  display: block;
  width: 100%;
  transition: 0.3s fill ease-in-out;
  position: relative;
  z-index: 4;
}

.logo:hover {
  fill: midGrey;
}

.logo svg {
  display: inline-block;
}

.arrow {
  position: relative;
}

.arrow::before,
.arrow::after {
  content: '';
  position: absolute;
  left: calc(100% + 24px);
  top: 15%;
  width: 15px;
  height: 3px;
  background: black;
  transition: 0.3s all ease-in-out;
}

.logo:hover .arrow::before,
.logo:hover .arrow::after {
  background: midGrey;
}

.arrow::before {
  transform: translate(-6px, 0) rotate(30deg);
}

.arrow::after {
  transform: translate(6px, 0) rotate(-30deg);
}

.panelIsVisible .arrow::before {
  transform: translate(-6px, 0) rotate(-30deg);
}

.panelIsVisible .arrow::after {
  transform: translate(6px, 0) rotate(30deg);
}

.list {
  padding-left: 0;
  margin: 0;
  list-style: none;
}

.link {
  color: inherit;
  text-decoration: none;
  display: block;
  margin: 0;
}

@media only screen and (min-width: 769px) {
  .header {
    height: smallHeight;
    min-height: minSmallHeight;
    display: flex;
    composes: thickBorder;
  }

  .logoWrapper {
    height: 100%;
  }

  .logo {
    padding: 0 13px;
    height: 100%;
  }

  .logo svg {
    max-width: 100%;
    height: calc(100% - 26px);
  }

  .logoWrapper,
  .navWrapper {
    flex-basis: 50%;
  }

  .navWrapper {
    position: relative;
    padding-right: 5%;
  }

  .nav {
    display: flex;
    align-items: flex-end;
    position: relative;
    z-index: 5;
    height: 100%;
  }

  .nav ul {
    flex-basis: 100%;
  }

  .list {
    display: flex;
    padding: 0 15px;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .link {
    color: midGrey;
    transition: 0.3s color ease-in-out;
    composes: responsiveTypeBig;
  }

  .listItem:hover .link,
  .listItemIsActive .link {
    color: inherit;
  }
}
