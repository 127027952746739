@value utils: '../styles/utils.module.css';
@value resetBtn from utils;

@value topHeight: max(10%, 54px);

.header {
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  background: white;
  height: topHeight;
  padding: 20px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 20px;
  background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
}

.header svg {
  width: 100%;
  max-width: 240px;
}

.logoWrapper {
  composes: resetBtn;
  padding: 5px 7px;
  border: 2px solid black;
  cursor: pointer;
}

.tabsPanel {
  position: fixed;
  z-index: 2;
  transition: 0.3s all ease-in-out;
  opacity: 0;
  transform: translate(0, -20px);
  left: 0;
  top: topHeight;
  bottom: 0;
  right: 0;
  background: white;
  padding: 0 40px;
  pointer-events: none;
}

.tabsPanelIsVisible {
  pointer-events: auto;
  opacity: 1;
  transform: none;
}

.main {
  position: relative;
  top: topHeight;
  height: 90%;
}

.contentsWrapper {
  padding: 20px 40px;
}
