@value utils: '../../styles/utils.module.css';
@value palette: '../../styles/palette.module.css';
@value typography: '../../styles/typography.module.css';
@value resetBtn, resetList, thickBorder, smallHeight, bigHeight, maxBigHeight from utils;
@value midGrey from palette;
@value n1, v1, n1Bold, v1Bold from typography;

@media only screen and (min-width: 769px) {
  .panelWrapper {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .panel {
    position: relative;
    width: 42.85%;
    max-width: 700px;
    height: 100%;
    overflow: hidden;
    background: white;
    composes: thickBorder;
    transition: 0.3s transform ease-in-out, 0.3s opacity ease-in-out;
    transform: translate(-30px, 0);
    opacity: 0;
    pointer-events: none;
  }

  .panelIsVisible .panel {
    transform: none;
    opacity: 1;
    pointer-events: auto;
  }

  .contentsWrapper {
    height: 100%;
    overflow-y: auto;
    padding: 0 10px 140px 0;
    position: relative;
    top: smallHeight;
  }

  .contents {
    margin-top: 20px;
    padding: 0 10px;
  }

  .close {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    z-index: 0;
    composes: resetBtn;
  }

  .panelWrapper {
    pointer-events: none;
  }

  .panelWrapper.panelIsVisible {
    pointer-events: visible;
  }
}
