.wrapper {
  position: absolute;
  display: flex;
  align-items: center;
  background: white;
  opacity: 0.8;
  transform: none;
  font-size: 20px;
  pointer-events: none;
}

.left {
  justify-content: flex-start;
}

.center {
  justify-content: center;
}
