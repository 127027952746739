.wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  position: relative;
  opacity: 1;
  transition: 0.3s opacity ease-in-out;
}

.wrapperIsHidden {
  opacity: 0;
}

.svg {
  overflow: visible;
}
