@value palette: '../../styles/palette.module.css';
@value utils: '../../styles/utils.module.css';
@value typography: '../../styles/typography.module.css';
@value midGrey from palette;
@value resetBtn, smallHeight, bigHeight, maxBigHeight from utils;
@value v1Bold, n1Bold, s1Bold from typography;

.inner,
.textareaOutput,
.input,
.submitBtn,
.skipBtn,
.author {
  composes: v1Bold;
}

.author {
  display: block;
  margin: 0 0 20px;
}

.answerLength,
.zipErrorMessage {
  composes: s1Bold;
}

.inner {
  width: 100%;
  height: 100%;
}

.inner p {
  margin: 0;
}

.part1Outer,
.part1,
.part2 {
  width: 100%;
  height: 100%;
}

.part1,
.part2 {
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.form {
  flex-grow: 1;
}

.formInner {
  position: relative;
}

.answerLength {
  position: absolute;
  bottom: 4px;
  right: 4px;
}

.inner,
.inner input,
.inner textarea {
  color: white;
}

.input {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  padding: 2px 5px;
  border: none;
  border-bottom: 3px solid white;
}

.textarea {
  position: relative;
  overflow: hidden;
}

@value textareaHeight: 170px;

.textareaHint span,
.textareaOutput span {
  padding-bottom: 3px;
}

.part1 .formInner,
.textarea,
.inner .textareaInput,
.textareaOutput,
.textareaHint {
  height: textareaHeight;
  overflow: hidden;
  word-wrap: break-word;
}

.inner .textareaInput {
  border: 0;
  padding: 0;
  resize: none;
  width: 100%;
  color: transparent;
  caret-color: white;
  outline-offset: -4px;
}

.textareaOutput {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
}

.textareaOutput span,
.textareaHint span {
  border-bottom: 3px solid white;
}

.textareaHint {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
  color: transparent;
}

.btns {
  position: relative;
}

.submitWrapper {
  text-align: right;
}

.submitBtn {
  composes: resetBtn;
  position: relative;
  pointer-events: none;
  transition: 0.3s all ease-in-out;
}

.submitBtn.submitBtnBig {
  pointer-events: auto;
}

.skipBtn {
  composes: resetBtn;
  position: absolute;
  top: 0;
  left: 0;
}

/*
 * Part 1
 */
.part1 .form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.part1 .lowerPart {
  position: relative;
}

/*
 * Part 2: ZIP
 */
.part2 {
  background: black;
}

.part2 .form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.zipField {
  margin-bottom: 40px;
  position: relative;
}

.zipErrorMessage {
  position: absolute;
  top: 100%;
  left: 0;
}

.zipInput {
  width: 140px;
}

.zipInput::-webkit-outer-spin-button,
.zipInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.zipInput[type='number'] {
  -moz-appearance: textfield;
}

.submitStatus {
  position: absolute;
  left: 0;
  top: 100%;
  width: 100%;
  padding: 20px;
  background: black;
}

.submitStatusMessage {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: black;
  border-radius: 11px;
}

.submitStatusMessage span {
  margin: auto;
}

@media only screen and (max-width: 768px) {
  .wrapper {
    position: relative;
    z-index: 1;
    height: 100%;
  }

  .modal {
    height: 100%;
  }

  .closeBtn {
    display: none;
  }

  .part1,
  .part2 {
    padding: 40px 40px;
    position: relative;
  }

  .form {
    padding: 40px 0 0;
  }

  .textareaHint,
  .textareaInput,
  .textareaOutput {
    line-height: 2.375rem;
  }
}

@media only screen and (min-width: 769px) {
  .wrapper {
    position: fixed;
    top: 50%;
    left: 3px;
    transform: translate(0, -50%);
    height: bigHeight;
    max-height: maxBigHeight;
    right: 3px;
    background: rgba(0, 0, 0, 0.25);
    z-index: 2;
    overflow: hidden;
  }

  .modal {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    pointer-events: none;
    display: flex;
  }

  .inner {
    margin: auto;
    pointer-events: auto;
    position: relative;
    width: 470px;
    height: 470px;
    z-index: 1;
  }

  .closeBtn {
    composes: resetBtn;
    display: block;
    width: 100%;
    height: 100%;
    background: transparent;
  }

  .inner {
    border-radius: 5px;
  }

  .part1,
  .part2 {
    border-radius: 11px;
    padding: 14px 24px 10px;
    overflow: hidden;
  }

  .form {
    padding: 6px 0 0;
  }

  .textareaHint,
  .textareaInput,
  .textareaOutput {
    line-height: 2.125rem;
  }
}
