/*
 * Start typographic values
 */

@media only screen and (max-width: 768px) {
  .v1Base {
    /* 30.5/41 */
    font-size: 1.25rem;
    line-height: 1.875rem;
  }

  .n1Base {
    /* 21/24 */
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .s1Base {
    /* 13/19 */
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
}

@media only screen and (min-width: 769px) {
  .v1Base {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .n1Base {
    font-size: 1rem;
    line-height: 1.4rem;
  }

  .s1Base {
    font-size: 0.8125rem;
    line-height: 1.125rem;
  }
}

/*
 * End values
 */

.bold {
  font-family: 'Graphik-Bold';
}

.medium {
  font-family: 'Graphik-Medium';
}

.underline {
  text-decoration: underline;
}

.v1 {
  composes: v1Base;
  composes: medium;
}

.v1Bold {
  composes: v1Base;
  composes: bold;
  letter-spacing: 0.8px;
}

.n1 {
  composes: n1Base;
  composes: medium;
}

.n1Bold {
  composes: n1Base;
  composes: bold;
}

.s1 {
  composes: s1Base;
  composes: medium;
}

.s1Bold {
  composes: s1Base;
  composes: bold;
  letter-spacing: 0.5px;
}

/*
 * Used where type should fit 100% viewport in all cases
 */
.responsiveTypeBig {
  font-size: min(2vw, 2.2vh);
  line-height: 1.44;
}

.responsiveTypeSmall {
  font-size: min(1vw, 1.8vh);
  line-height: 1.44;
}
