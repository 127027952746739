@value utils: '../../styles/utils.module.css';
@value typography: '../../styles/typography.module.css';
@value resetBtn from utils;
@value n1 from typography;

@media only screen and (min-width: 769px) {
  .wrapper {
    display: none;
    visibility: hidden;
  }
}

@media only screen and (max-width: 768px) {
  .wrapper {
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 20px 40px 10px;
    background: white;
    z-index: 5;
  }

  .closeBtnWrapper {
    text-align: right;
    padding: 0 0 20px;
  }

  .closeBtn {
    composes: resetBtn;
    position: relative;
    transform: translate(18px, 0);
    width: 44px;
    height: 44px;
  }

  .closeBtn::before,
  .closeBtn::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 24px;
    height: 2px;
    background: black;
  }

  .closeBtn::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .closeBtn::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  .text {
    padding-bottom: 60px;
    composes: n1;
  }

  .handleWrapper {
    text-align: center;
  }

  .handle {
    display: inline-block;
    width: 140px;
    height: 3px;
    border-radius: 4px;
    background: black;
    margin-top: 30px;
  }
}
