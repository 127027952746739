@value typography: '../../styles/typography.module.css';
@value s1, s1Bold from typography;

.cellBorder {
  position: absolute;
  border: 1px solid black;
  border-radius: 2px;
  pointer-events: none;
}

.flippablePanel {
  position: absolute;
  color: white;
  perspective: 600px;
  composes: s1Bold;
}

.inner {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: 0.3s transform ease-in-out;
  position: relative;
}

.flippablePanel:hover .inner {
  transform: rotateY(-180deg);
}

.side {
  width: 100%;
  height: 100%;
  padding: 10px;
  border-radius: 10px;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.back {
  transform: rotateY(180deg);
  text-align: center;
}

.prompt {
  display: inline-block;
  margin-bottom: 7px;
}

.answer {
  border-bottom: 2px solid;
  padding-bottom: 2px;
  line-height: 1.5;
}

.author,
.borough {
  display: block;
}
