@value utils: '../../styles/utils.module.css';
@value typography: '../../styles/typography.module.css';
@value thickBorder, smallHeight, minSmallHeight from utils;
@value s1 from typography;

.logo {
  color: inherit;
  text-decoration: none;
}

.logo img {
  display: block;
}

@media only screen and (max-width: 768px) {
}

@media only screen and (min-width: 769px) {
  .footer {
    height: smallHeight;
    min-height: minSmallHeight;
    background: white;
    padding: 0 10px;
    composes: thickBorder;
    display: flex;
    justify-content: space-between;
  }

  .promoters {
    height: 100%;
    padding: 11px 0;
  }

  .logo {
    display: inline-block;
    margin-right: 45px;
    height: 100%;
  }

  .logo img {
    height: 100%;
  }

  .legal {
    composes: s1;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-basis: 230px;
    flex-grow: 1;
  }

  .legalLink {
    color: inherit;
  }
}
