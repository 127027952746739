@value utils: '../../styles/utils.module.css';
@value palette: '../../styles/palette.module.css';
@value typography: '../../styles/typography.module.css';
@value resetList, resetBtn, smallHeight from utils;
@value midGrey from palette;
@value n1, v1 from typography;

.btn {
  composes: resetBtn;
  composes: n1;
}

@media only screen and (max-width: 768px) {
  .tabs {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .item {
    padding: 25px 0;
  }

  .btn {
    font-size: 1.5rem;
  }
}

@media only screen and (min-width: 769px) {
  .tabs {
    composes: resetList;
    display: flex;
    position: relative;
    top: smallHeight;
    padding: 10px 0 20px;
  }

  .tabs::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: linear-gradient(to bottom, white, rgba(255, 255, 255, 0));
  }

  .item {
    padding: 10px;
    margin-right: 3%;
    color: midGrey;
    transition: 0.3s color ease-in-out;
  }

  .item:hover,
  .itemIsActive {
    color: black;
  }
}
