@value utils: '../styles/utils.module.css';
@value palette: '../styles/palette.module.css';
@value thickBorder from utils;

@media only screen and (min-width: 769px) {
  .main {
    height: 87.5%;
    overflow: hidden;
    composes: thickBorder;
    border-top: 0;
    border-bottom: 0;
    background: white;
  }
}
