.legend {
  position: fixed;
  bottom: max(6.25%, 54px);
  left: 0;
  padding: 7px 10px;
  background: black;
  color: white;
  z-index: 2;
  font-size: 14px;
}
